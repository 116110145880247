import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    $('.m-dna-accordion-content').hide();

    $('.m-dna-accordion-heading').on('click', function(){
        if($(this).closest('.m-dna-accordion-section').hasClass('-hidden')) {
            $('.m-dna-accordion-section').addClass('-hidden');
            $('.m-dna-accordion-content').hide();
            $(this).closest('.m-dna-accordion-section').removeClass('-hidden');
            $(this).closest('.m-dna-accordion-section').find('.m-dna-accordion-content').show('slow');
            $('.m-dna-accordion-button.fa-plus').removeClass('-close');
            $('.m-dna-accordion-button.fa-minus').addClass('-close');
            $(this).find('.fa-plus').addClass('-close');
            $(this).find('.fa-minus').removeClass('-close');
        } else {
            $('.m-dna-accordion-content').hide('toggle');
            $('.m-dna-accordion-section').addClass('-hidden');
            $('.m-dna-accordion-button.fa-plus').removeClass('-close');
            $('.m-dna-accordion-button.fa-minus').addClass('-close');
        };
    });

    if($('.-animated-dna')[0]) {
        const dnaObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('-animate');
                $('.statform').addClass('radar-animation');
            }
            });
        }, {threshold: 0.5});
        
        dnaObserver.observe(document.querySelector('.-animated-dna'));
    };
});