import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';

jQuery('.m-news-carousel.-isCarousel').each(function () {

    let slickConf = {
        ...slickConf,
        arrows: false,
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 767,

                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    
    $(this).slick(slickConf);

});
