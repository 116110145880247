import 'jquery';
import 'slick-carousel';
import { default as $ } from 'jquery';

$('.m-timeline-carousel.-isCarousel').slick({
    infinite: false,
    slidesToShow: 1,
    rows: 0,
    arrows: true,
    prevArrow: $('.m-timeline-arrow.slick-prev'),
    nextArrow: $('.m-timeline-arrow.slick-next'),
    dots: false,
    draggable: false,
    // adaptiveHeight: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    // centerMode: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1.5,
            }
        }
    ]
});

jQuery(document).ready( function() {
    if ($(window).innerWidth() > 767) {
        $('.m-timeline-content').removeClass('-visible');
        $('.m-timeline-content').removeClass('-hide');
    
        $('.m-timeline-container').each(function() {
            $(this).children(".m-timeline-content:first").addClass('-visible');
        });
    
        $('.m-timeline-arrow').on('click', function(){
            var timelineDate = '#' + $('.slick-current').attr('data-name');
            $('.m-timeline-content').removeClass('-visible');
            $(timelineDate).addClass('-visible'); 
    
        });
    
    } else {
            $('.m-timeline-container').each(function() {
            $(this).children(".m-timeline-content:first").removeClass('-hide');
        });
    
        $('.m-timeline-arrow').on('click', function(){
            var timelineDate = '#' + $('.slick-current').attr('data-name');
            $('.m-timeline-content').addClass('-hide');
            $(timelineDate).removeClass('-hide');
    
        });
    };
    
    $('.m-timeline-carousel').each(function() {
        $(this).find(".m-timeline-dates:last").children(".m-timeline-dash-container").addClass('-nodisplay');
    });

});