// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './age-cookie/age-cookie';
import './basket-update/basket-update';
import './bottle-reg/bottle-reg';
import './dna/dna';
import './faq/faq';
import './gallery/gallery';
import './media-text/media-text';
import './nav/nav';
import './news/news';
import './posts/posts';
import './product/product';
import './products/products';
import './slick-common/slick-common';
import './tabs/tabs';
import './team/team';
import './timeline/timeline';
import './language/language';
import './explore/explore';
import './ripple/ripple';
import './animations/animations';
import './video-block/video';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
