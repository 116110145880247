import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready( function() {
    $('.m-media-alt-button').removeClass('-upsidedown');
    $('.m-media-alt-extra').slideUp();

    $('.m-media-alt-button').on('click', function(){
        $(this).closest('.m-media-alt-card-content').find('.m-media-alt-extra').slideToggle();
        $(this).closest('.m-media-alt-card').toggleClass('long');
        $(this).toggleClass('-upsidedown');

        if($('.m-media-alt-button').hasClass('-upsidedown')) {
            $(this).html('Read Less<i class="fa-solid fa-chevron-up ms-4"></i>');
        } else {
            $(this).html('Read More<i class="fa-solid fa-chevron-down ms-3"></i>');
        };
    });
});