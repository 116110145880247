import 'jquery';
import { default as $ } from 'jquery';

$.fn.toggleText = function(t1, t2){
    if (this.text() == t1) this.text(t2);
    else                   this.text(t1);
    return this;
};

jQuery(document).ready( function() {
    $('.m-video-expand').slideUp();

    $('.expandCard').on('click', function(){
        $(this).closest('.m-video-card').find('.m-video-expand').slideToggle();
        $(this).toggleText('Read less', 'Read more');
    });
});