import 'jquery';
import { default as $ } from 'jquery';

$(document).ready(function () {
    $('.m-picker-button').on('click', function(){
        $('.m-picker-select').slideToggle();
    });
});


/*
* If original language is selected, then the value of current_language will be empty. 
* Otherwise, it will contain the current selected language code.
*/

// var current_language = gt_get_cookie('googtrans').split('/').pop();
// var current_lang = document.getElementsByTagName('html')[0].getAttribute('lang');
// var language = '';

// function gt_get_cookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for(var i = 0; i <ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }

// function check_lang() {
//     if(current_language == '') {
//         language = "English";
//     }else if(current_language == 'de') {
//         language = "Deutsch";
//     }else if(current_language == 'es') {
//         language = "Espanol";
//     }else if(current_language == 'fr') {
//         language = "Français";
//     }else if(current_language == 'zh-CN') {
//         language = "简体中文";
//     }else if(current_language == 'ja') {
//         language = "日本語";
//     };
// }

// $(document).ready(function () {
    // current_language = gt_get_cookie('googtrans').split('/').pop();
    // check_lang();
    // $('#langName').html(language);
    // if(language == 'Espanol') {
    //     language = "Español";
    // };
    // $("a[title='"+language+"']").addClass('-active');
    
    // $('.glink').on('click', function(event){
    //     current_language = gt_get_cookie('googtrans').split('/').pop();
    //     check_lang();
    //     $('#langName').html(language);
    //     $('.glink').removeClass('-active');
    //     $(this).addClass('-active');
    //     console.log(language);
    // });
// });

