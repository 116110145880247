import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

$(document).ready( function() {

    var imageUrl = $("#post").find(".m-single-image").attr('src');
    var imageAlt = $("#post").find(".m-single-image").attr('alt');
    var quote = $("#post").find(".m-single-quote-text").text();
    var quoteAuth = $("#post").find(".m-single-quote-auth").text();
    var postText = '<div class="m-single-break mt-5 mb-4"><img class="m-single-image-mobile w-100" src="'+imageUrl+'" alt="'+imageAlt+'" /><div class="m-single-quote text-center d-flex flex-column align-items-center mt-4"><h2 class="m-single-quote-text -white f-garamond mb-4">'+ quote + '</h2><h3 class="m-single-quote-auth m-fontsize-content -white f-montserrat -bold text-gradient text-uppercase pb-3">'+quoteAuth+'</h3></div></div>';
    
    $(".m-single-mobileBreak").append(postText);


    $('.m-single-news-container.-isCarousel').each( function() {        
        
        $(this).slick({
            pauseOnHover: false,
            draggable: true,
            infinite: false,
            autoplay: false,
            controls: true,
            arrows: false,
            dots: false,
            rows: 0,
            margin: 20,
            slidesToShow: 3.2,
            slidesToScroll: 1,
            autoplaySpeed: 4000,
            
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 2.5,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2.2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1.2,
                    }
                }
            ]
        });
    });
});