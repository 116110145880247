import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';

jQuery(document).ready(function () {
    jQuery('.m-explore-list.-isCarousel').each(function () {

        let slickConf = {
            ...slickConf,
            arrows: false,
            dots: false,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            centerMode: false,
            variableWidth: false,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1600,

                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 992,

                    settings: {
                        slidesToShow: 2.5,
                        infinite: false,
                        autoplay: false,
                    }
                },
                {
                    breakpoint: 576,

                    settings: {
                        slidesToShow: 2,
                        infinite: false,
                        autoplay: false,
                    }
                },
                {
                    breakpoint: 480,

                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        infinite: false,
                        autoplay: true,
                    }
                }
            ]
        };
        
    $(this).slick(slickConf);

    });
});