import { default as $ } from 'jquery';

jQuery(document).ready( function() {
    $('.m-tabs-text').removeClass('-visible');

    $('.m-tabs-border').each(function() {
        $(this).children(".m-tabs-tab:first").removeClass('-notactive').prop('disabled', true);
        var contentID = '#' +  $(this).children(".m-tabs-tab:first").attr('data-name');
        $(contentID).addClass('-visible');
    });

    $('.m-tabs-tab').on('click', function(){
        $('.m-tabs-tab').addClass('-notactive').prop('disabled', false);
        $(this).removeClass('-notactive').prop('disabled', true);
        $('.m-tabs-text').removeClass('-visible');
        var contentID = '#' + $(this).attr('data-name');
        $(contentID).addClass('-visible'); 
    });
});