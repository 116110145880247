import 'jquery';
import { default as $ } from 'jquery';
import 'slick-carousel';

jQuery(document).ready(function () {
    jQuery('.m-products-list.-mobile.-isCarousel').each(function () {

        let slickConf = {
            ...slickConf,
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 2.5,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            centerMode: false,
            variableWidth: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 768,

                    settings: {
                        slidesToShow: 1.5,
                    }
                }
            ]
        };
        
    $(this).slick(slickConf);

    });
});