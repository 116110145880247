import { default as $ } from 'jquery';
import 'slick-carousel';

$('.m-team-carousel.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: '20px',
    arrows: true,
    prevArrow: '<button type="button" aria-label="Previous" class="slick-prev"><i class="fa-solid fa-chevron-left"></i></button>',
    nextArrow: '<button type="button" aria-label="Next" class="slick-next"><i class="fa-solid fa-chevron-right"></i></button>',
    draggable: true,
    pauseOnHover: true,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    adaptiveHeight: false,
    rows: 0,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2.1,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1.5,
                arrows: false,
            }
        },
        {
            breakpoint: 576,

            settings: {
                slidesToShow: 1.1,
                arrows: false,
            }
        }
    ]
});

