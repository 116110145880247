import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';
import { default as $ } from 'jquery';


$('.m-gallery-carousel.-isCarousel').slick({
    infinite: true,
    slidesToShow: 5,
    arrows: false,
    dots: false,
    draggable: true,
    adaptiveHeight: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    autoplaySpeed: 4000,
    centerMode: true,
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,

            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 3,
                centerPadding:'20px',
            }
        }
    ]
});

jQuery(document).ready( function() {
    $('#galleryMain').hide();
    $('#galleryMainVideo').hide();

    var imageSrcfirst = $('.slick-current').find('.m-gallery-media-type').attr('src');
    var mediaTypefirst = $('.slick-current').find('.m-gallery-media-type').attr('data-type');

    if(mediaTypefirst == 'mp4') {
        $('#galleryMainVideoSrc').attr('src', imageSrcfirst);
        $('#galleryMainVideo').show();
        $('#galleryMain').hide();
        $('#galleryMainVideo')[0].load();
    } else {
        $('#galleryMain').attr('src', imageSrcfirst);
        $('#galleryMain').show();
        $('#galleryMainVideo').hide();
    }
    
    $('.m-gallery-carousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var imageSrc = $('.slick-current').find('.m-gallery-media-type').attr('src');
        var mediaType = $('.slick-current').find('.m-gallery-media-type').attr('data-type');
    
        if(mediaType == 'mp4') {
            $('#galleryMainVideoSrc').attr('src', imageSrc);
            $('#galleryMainVideo').show();
            $('#galleryMain').hide();
            $('#galleryMainVideo')[0].load();
        } else {
            $('#galleryMain').attr('src', imageSrc);
            $('#galleryMain').show();
            $('#galleryMainVideo').hide();
        };
    });
});
