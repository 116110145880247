import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {

    // General animations
          
        // Add transition animation on load
            $('.a-fadeIn-t').addClass('-opacity');


        // Image and text content fade in animation
        const blocks = document.querySelectorAll('.-animated-ct');

        const observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => { 
                if (!entry.isIntersecting) {
                    return;
                };
            
                entry.target.classList.add('-animate');
            });
        });
          
        blocks.forEach(block => observer.observe(block));


        // Fade in one by one animation
        const cards = document.querySelectorAll('.-animated-carousel');

        const observer2 = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => { 
                if (!entry.isIntersecting) {
                    return;
                }

              entry.target.classList.add('-animate-many');
            });
        });
          
        cards.forEach(card => observer2.observe(card));


        // Left to right reveal animation
        if($('.-animated-swipe')[0]) {
            const observer3 = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('-animate-swipe');
                    }
                });
            });
            
            observer3.observe(document.querySelector('.-animated-swipe'));
        }

        // Fade and slide up animation
        const sections = document.querySelectorAll('.-animated-fadeInUp');

        const observer4 = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => { 
                if (!entry.isIntersecting) {
                    return;
                }

                entry.target.classList.add('-animate-fadeInUp')
            });
        });
            
        sections.forEach(section => observer4.observe(section));


        // Text slide up animation
        const texts = document.querySelectorAll('.a-textslide');

        const observer5 = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => { 
                if (!entry.isIntersecting) {
                    return;
                }

                entry.target.classList.add('a-slideUp-text');
            });
        });
            
        texts.forEach(text => observer5.observe(text));


    // Science block animation
    if($('.-animated-science')[0]) {
        const science = new IntersectionObserver(entries => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('-animate-science');
            }
            });
        }, {threshold: 0.5});
        
        science.observe(document.querySelector('.-animated-science'));
    };

    
    // Explore block animation
    if($('.-animated-explore')[0]) {
        const explore = new IntersectionObserver(entries => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('-animate-explore');
            }
            });
        });
        
        explore.observe(document.querySelector('.-animated-explore'));
    };


    // Video alt block animation
    if($('.-animated-card-container')[0]) {
        const videoalt = new IntersectionObserver(entries => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('-slideDown');
            }
            });
        });
            
        videoalt.observe(document.querySelector('.-animated-card-container'));
    };


    // Values spider diagram animation
    if($('.-animated-values')[0]) {
        const values = new IntersectionObserver(entries => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('-animate');
            }
            });
        }, {threshold: 0.5});
        
        values.observe(document.querySelector('.-animated-values'));
    };


    // Animate line on team block
    const lines = document.querySelectorAll('.-animated-line');

    const observerLine = new IntersectionObserver(function(entries, observer) {
        entries.forEach((entry) => { 
            if (!entry.isIntersecting) {
                return;
            }

            entry.target.classList.add('-animate-line');
        });
    });
        
    lines.forEach(line => observerLine.observe(line));


    // Animate line behind buttons
    const buttonlines = document.querySelectorAll('.-animated-buttonline');

    const buttonLineObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach((entry) => { 
            if (!entry.isIntersecting) {
                return;
            }

            entry.target.classList.add('-animate-buttonline');
        });
    }, {threshold: 0.2});
        
    buttonlines.forEach(buttonline => buttonLineObserver.observe(buttonline));


    // News animations
    const articles = document.querySelectorAll('.-animated-news');

    const observerNews = new IntersectionObserver(function(entries, observer) {
        entries.forEach((entry) => { 
            if (!entry.isIntersecting) {
                return;
            }

            entry.target.classList.add('-animate-news');
        });
    }, {threshold: 0.3});
        
    articles.forEach(article => observerNews.observe(article));


    // Card fade in and title splitting animation
    Splitting(); 

    ScrollOut({  
        threshold: .3,
        once: true,
    });
});