import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function() {
    if ($(window).width() < 768) {
        $('#header').addClass('-dark');  
        
    } else {
        var header = $("#header");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("darkHeader");
            } else {
                header.removeClass("darkHeader");
            }
        });    
    }

    $(".m-header-nav-list").children(".menu-item-has-children").on('mouseenter', function(event){
        $(this).find('ul.sub-menu').first().slideDown().addClass('-dropdown');

        if($(this).hasClass('menu-item-has-children')) {
            $('.m-header').addClass('-background').removeClass('-dark');
        }
    });

    $(".m-header-nav-list").children(".menu-item-has-children").on('mouseleave', function(event){
        $(this).find('ul.sub-menu').first().hide().removeClass('-dropdown');
        $('.m-header').removeClass('-background').addClass('-dark');

        if($('.sub-menu').hasClass('-dropdown')) {
            $('.m-header').addClass('-background').removeClass('-dark');
        } else {
            $('.m-header').removeClass('-background').addClass('-dark');
        }
    });

    jQuery('#mobileDropdown').slideUp();

    jQuery('#mobileDropdownButton').on('click', function(){
        jQuery('#mobileDropdown').slideToggle();
        $('.m-header').toggleClass('-background').toggleClass('-dark');
    }); 

    $('.menu-item-has-children').on('click', function() {
        $(this).find('.sub-menu').slideToggle();
        $(this).toggleClass('arrow-up');
    });
});