import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    $('.m-product-accordion-content').hide();

    $('.m-product-accordion-heading').on('click', function(){
        if($(this).closest('.m-product-accordion-section').hasClass('-hidden')) {
            $('.m-product-accordion-content').slideUp();
            $('.m-product-accordion-section').addClass('-hidden');
            $(this).closest('.m-product-accordion-section').removeClass('-hidden');
            $(this).closest('.m-product-accordion-section').find('.m-product-accordion-content').slideDown();
            $('.m-product-accordion-button.fa-plus').show();
            $('.m-product-accordion-button.fa-minus').hide();
            $(this).find('.fa-plus').hide();
            $(this).find('.fa-minus').show();
        } else {
            $('.m-product-accordion-content').slideUp();
            $('.m-product-accordion-section').addClass('-hidden');
            $('.m-product-accordion-button.fa-plus').show();
            $('.m-product-accordion-button.fa-minus').hide();
        };
    });

    if($('div').hasClass('woocommerce-product-gallery__image--placeholder')) {
        $('.m-product-row').addClass('-height');
        $('.m-product-media').addClass('-hide');
        $('.entry-summary').removeClass('col-lg-6').removeClass('col-xxl-5').removeClass('text-md-start').addClass('col-lg-10');
        $('.product_title').removeClass('text-md-start');
        $('.m-product-subtitle').removeClass('text-md-start');
        $('.m-product-desc').removeClass('text-start');
    };
});