import 'jquery';
import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    $('.m-faq-answer').hide();
    $('.m-faq-minus').hide();

    jQuery('.m-faq-question').on('click', function(){
        if($(this).hasClass('-active')) {
            $(this).find('.fa-minus').hide();
            $(this).find('.fa-plus').show();
            $('.m-faq-question').removeClass('-active');
            $(this).removeClass('-active');
            $(this).closest('.m-faq-section').find('.m-faq-answer').slideUp();
        }else{
            $('.m-faq-answer').slideUp();
            $('.fa-minus').hide();
            $('.fa-plus').show();
            $(this).find('.fa-minus').show();
            $(this).find('.fa-plus').hide();
            $('.m-faq-question').removeClass('-active');
            $(this).toggleClass('-active');
            $(this).closest('.m-faq-section').find('.m-faq-answer').slideDown();
        };
    });
});